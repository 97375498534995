.session-page{
    /* margin-top: 4.5em !important; */
    text-decoration: none !important;

}
.clear-filter{
    display:flex;
    justify-content:flex-end;
    margin-top:0px;
    margin-bottom:10px; 
    padding:0px 32px;
}
.ellipsis {
    display: -webkit-box;
    max-width: 80%;
    margin: 0 auto;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 10px !important;
  }
.session-page .filter-label{
    font-size: 12px !important;
    font-weight: bold !important;
    background-color: #404040;
    width: 100%;
    border-radius: 20px;
    padding: 5px 10px !important;
    color: #ffffff !important;
    margin: 0 0 0.5em 0 !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.session-page .filter-options{
    font-size: 12px !important;
    font-weight: bold !important;
    background-color: #ffffff;
    width: 100%;
    border-radius: 20px;
    padding: 3px 10px !important;
    color: #404040 !important;
    margin: 0 0 0.5em 0 !important;
    justify-content: space-between;
}
.session-page .MuiFormControlLabel-label{
    font-size: 12px !important;
    color: #404040;
    font-weight: bold !important;
}
.session-page .MuiRadio-root{
    padding: 0 !important;
}
.session-page .clear-filter-btn{
    display:flex;
    justify-content:flex-end;
    margin-top:0px;
    margin-bottom:0px; 
    padding: 0px;
}
.session-page .clear-filter-btn button {
    margin: 0px;
    padding: 0px;
    display:flex;
    justify-content:flex-end;
}
.session-page .MuiSelect-icon{
    color: #404040 !important;
}
.session-page .form-control{
    padding: 0 2em !important;
    width: 100%;
    margin-bottom: 2em !important;
}
.session-page .form-control .MuiRadio-root span svg:nth-child(2){
    font-size: 2.25rem;
    left: -8px;
    top: -8px;
}

/* Video card css */
.session-page .video-card:hover .overlay{
    /* display: block; */
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    border-radius: 10px;
    /* right: 0; */
    background-color: #404040;
    opacity: 0.4;
  }
  .session-page .video-card:hover .play-button{
    display: block !important;
    position: absolute;
    top: 0;
    left: 45%;
    z-index: 1;
    padding: 0;
    height: 30px;
    width: 30px;
    align-items: center;
  }
  .session-page .video-card:hover .play-button .MuiSvgIcon-root{
    margin-top: 2px;
}
  .session-page .video-scroll-menu-text{
    font-size: 12px;
    margin: 0 !important;
    text-decoration: none !important;
}
.video-block-title Link{
    text-decoration: none !important;

}
.session-page .font-weight-bold{
    font-weight: bold;
}
.session-page .video-slider-media{
    height: 7em;
    width: 100%;
    object-fit: cover;
    /* padding: 5px; */
}
.session-page .video-card{
    border-radius: 10px !important;
    height: 100%;
}
.session-page .video-card-content{
    padding: 10px !important;
}
.session-page .arrow-buttons{
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    right: 1%;
    user-select: none;
    margin-top: 5.5em;
    height: fit-content;
    width: fit-content;
    border-radius: 100%;
    padding: 2px;
    border: none;
    background-color: #ffffff;
}
.session-page .scroll-to-top-button{
    z-index: 2;
    background-color: #404040 !important;
    color: #ffffff !important;
    position: fixed !important;
    bottom: 1em;
    right: 0;
    padding: 6px 8px 2px 8px !important;
    border-radius: 30px !important;
    min-width: fit-content !important;
}
.session-page .video-block-title{
    margin-top: 0;
    font-size: 12px;
    font-weight: 800 !important;
    color: #404040;
}
.session-page .favorite-button{
    background-color: transparent;
    outline: none;
    color: #51c0db;
    border: none;
    position: absolute;
    right: 0.5em;
    bottom: 0.5em;
    z-index: 3;
    cursor: pointer;
}
.session-page .favorite-icon{
    color: #51c0db;
}

.session-page-date-picker{
    width: 100%;
    background: #ffffff;
    border-radius: 30px;
}
.session-page-date-picker input{
    padding: 5px 10px;
}
.session-page-date-picker button{
    color: #404040;
}
.session-page-date-picker .MuiCalendarPicker-root .Mui-selected{
    background-color: #404040 !important;
}
.session-list{
    padding-right: 1em 1em 1em 2em !important;
}
.session-page .expand-icon{
    position: absolute;
    right: 0.5em;
    top: 0.1em;
}
@media screen and (max-width:899px) {
    .d-none{
        display: none !important;
    }
    .session-page .form-control{
        margin-bottom: 0 !important;
    }
    .session-page .session-list{
        padding: 24px 24px 24px 3em !important;
    }
}