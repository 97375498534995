.banner {
    position: relative;
    width: 100%;
    height: auto;
    color: #f1f1f1;
    object-fit: cover;
    background-repeat: no-repeat;
}

.bannerContents {
    margin-left: 30px;
    padding-top: 140px;
    height: 190px;
}

.banner-text{
    position: absolute;
    left: 2.8em;
    top: 8.5em;
}

.bannerTitle {
    color: #ffffff;
    font-style: italic;
    font-size: 3rem;
    max-width: 50%;
    font-weight: 800;
    padding-bottom: 0.3rem;
    margin-bottom: 5px;
}

.login-link {
    color: #ffffff;
    font-style: italic;
    font-size: 3rem;
    max-width: 30%;
    font-weight: 800;
    padding-bottom: 0.3rem;
}
.login-link button{
    padding: 10px 20px;
    color: #f1f1f1;
    background: #51c0db;
    font-size: 18px;
    font-weight: 500;
    border-radius: 5px;
    border: none;
    cursor: pointer;
}
.login-text{
    color: #ffffff;
    font-size: 14px;
    max-width: 50%;
    padding-bottom: 0.1rem;
    margin: 0;
}

.bannerDescription {
    width: 45rem;
    line-height: 1.3;
    font-size: 0.8rem;
    max-width: 360px;
    height: 80px;
}

.bannerFadeBottom {
    height: 7.4rem;
    background-image: linear-gradient(
        180deg,
        transparent,
        #e3f0f1,
        #f1f1f1
    );
}

.bannerButton {
    cursor: pointer;
    color: #fff;
    outline: none;
    border: none;
    font-weight: 700;
    border-radius: 0.2vw;
    margin-top: 1rem;
    margin-right: 1rem;
    background-color: #51c0db;
    text-transform: uppercase;
    padding: 0.5rem 2rem 0.5rem 2rem;
}

.bannerButton:hover {
    color: #036881;
    background-color: #e6e6e6;
    transition: all 0.2s;
}

.banner .overlay{
    /* display: block; */
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    right: 0;
    left: 0;
    background-color: #404040;
    opacity: 0.8;
}

  .banner .box {
    background-image: url('../../img/GetUpp-Line.png');
    position: absolute;
    top: 8em;
    background-size: 100%;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
  }

  @media screen and (max-width:956px) {
      .banner .box{
          top: 5em;
      }
      .bannerTitle{
          top: 30%;
          left: 2.8em;
      }
      .banner-background
      {
        min-height: 30rem;
        padding: 0px;
      }
  }
  @media screen and (max-width:768px) {
    .banner .box{
        display: none;
    }
      .bannerTitle{
          font-size: 1rem;
          max-width: 100%;
      }
      .banner-container .banner-content:nth-child(1){
        margin-bottom: 1em;
      }
    
      .banner-background
      {
        min-height: 17rem;
        padding: 0px;
      }

    .banner-text{
        position:absolute;
        top: 4.5em;
        left: 1.5em;
        width: 100%;
    }

    .login-text{
        color: #ffffff;
        font-size: 12px;
        max-width: 70%;
        padding-bottom: 0.1rem;
        margin: 0;
    }

    .bannerButton {
        cursor: pointer;
        color: #fff;
        outline: none;
        border: none;
        font-weight: 700;
        border-radius: 0.2vw;
        margin-top: 1rem;
        margin-right: 1rem;
        background-color: #51c0db;
        text-transform: uppercase;
        padding: 0.2rem 1rem 0.2rem 1rem;
    }
  }