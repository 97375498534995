.nav {
    position: fixed;
    top: 0;
    width: 100%;
    height: auto;
    z-index: 1; 
    

    /* Animations */
    transition-timing-function: ease-in;
    transition: all 0.5s;
}

.navbar{
    background-color: #f1f1f1 !important;
    color: #000000 !important;
    box-shadow: none !important;
    border-bottom: 2px solid #ffffff;
    margin-bottom: 1em;
}

.navWhite {
    background-color: #f1f1f1;
}

.navLogo {
    /* position: fixed; */
    top: 20px;
    left: 0;
    margin-left: 0.5em;
    width: 10em;
    object-fit: contain;
    flex-grow: 1;
    cursor: pointer;
}

.navAvatar,.navAvatar:hover {
    cursor: pointer;
    position: fixed;
    top: 20px;
    right: 2.7em;
    width: 43px;
    height: auto;
    background-color: transparent !important;
}

.navList {
    margin-left: 3em;
    list-style-type: none;
    height: 100%;
    justify-content: baseline;
    position: relative;
}

.navItems {
    margin-right: 20px;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
    color: #404040;
    text-decoration: none;
}

.navItems.active{
    color: #51c0db !important;
}

.navItems a:link {
    color: #51c0db;
    text-decoration: none;
}

.navItems a:visited {
    color: #51c0db;
}

.navItems a:hover {
    color: #51c0db;
    text-decoration: underline;
}

.navItems a:active {
    color: #51c0db;
}

.searchBlock, .searchIcon{
    display: flex;
    align-items: center;
}
.searchBlock{
    background: #ffffff;
    padding: 0 5px;
    border-radius: 15px;
    position: absolute;
    right: 6em;
}
.inputInput{
    font-size: 12px !important;
    padding: 7px 5px !important;
}
.searchIcon .MuiSvgIcon-root{
    height: 0.8em;
    width: 0.8em;
}

.language-icon{
    position: fixed !important;
    right: 0.5em;
    top: 0.5em;
}
.align-items-center{
    display: flex;
    align-items: center;
}
.flag-icon{
    width: 15px;
    margin-right: 7px;
}
.selected-language{
    font-size: 1.2em !important;
    margin-left: 5px;
}
.profile-links{
    text-decoration: none;
    color: #404040;
    width: 100%;
}
#change-profile-menu .MuiBackdrop-root{
    opacity: 0 !important;
}

/* Responsive view css */
.navIconHide{
    position: absolute !important;
    right: 0em;
    color: #404040 !important;
}
.mobile-drawer .MuiDrawer-paper{
    width: 50%;
}
.mobile-drawer .navItems{
    font-weight: 500;
    color: #404040;
    font-size: 14px;
    padding: 1em;
    margin: 0;
}
.mobile-drawer .navItems.active{
    color: #ffffff !important;
    background-color: #404040;
}
.mobile-searchIcon{
    display: flex;
    align-items: center;
    position: absolute;
    right: 4.2em;
    margin-top: 2px;
    cursor: pointer;
}
.mobile-searchIcon .MuiSvgIcon-root{
    height: 1em !important;
    width: 1em !important;
}
.mobile-search-close-icon{
    position: absolute;
    right: 1em;
    z-index: 2;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.mobile-search-block{
    background: #ffffff;
    padding: 3px 5px 3px 3px;
    border-radius: 30px;
    z-index: 1;
    position: absolute;
    left: 5px;
    width: 98%;
}
@media screen and (max-width:599px) {
    .language-icon{
        padding-top: 5px !important;
        right: 1.8em;
    }
    
}
@media screen and (min-width:599px) {
    .mobile-searchIcon{
        right: 5.2em;
    }
}