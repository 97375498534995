.navForm {
    position: fixed;
    top: 0;
    padding: 20px;
    width: 100%;
    height: 40px;
    z-index: 2; 
    

    /* Animations */
    transition-timing-function: ease-in;
    transition: all 0.5s;
}

.navFormWhite {
    background-color: #f1f1f1;
    z-index: 3;
}

.navFormLogo {
    position: fixed;
    top: 20px;
    left: 0;
    width: 120px;
    object-fit: contain;
    padding-left: 20px;
    cursor: pointer;
}

.navFormButton {
    position: absolute;
    right: 50px;
    top: 20px;
    padding: 10px 20px;
    color: #f1f1f1;
    background: #51c0db;
    font-size: 18px;
    font-weight: 500;
    border-radius: 5px;
    border: none;
    cursor: pointer;
}