.replay-video-player-grid{
    background-color: #ffffff;
    padding: 0.5em !important;
    border-radius: 5px !important;
    height: fit-content;
}
.Replay-Video-Player{
    /* margin-top: 5px; */
    background-color: #f1f1f1;
}
.player-category{
    margin-top: 0;
    font-size: 12px;
    font-weight: 800 !important;
    color: #404040;
}
.arrow-back-button{
    background-color: transparent;
    outline: none;
    color: #ffffff;
    border: 3px solid;
    border-radius: 100%;
    padding: 1px 3px !important;
    position: absolute;
    left: 1em;
    top: 1em;
    z-index: 1;
}
.arrow-back-icon{
    height: 1.3em !important;
    width: 1.3em !important;
}