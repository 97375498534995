.user-detail-block{
    margin: 4em 1em !important;
}
.user-detail-row{
    background-color: #ffffff;
    border-radius: 30px;
    width: 100%;
    font-size: 14px !important;
    color: #404040;
    font-weight: bold;
    margin-top: 1em !important;
}
.user-detail-row .MuiGrid-item{
    padding-top: 0 !important;
}
.user-detail-row.align-items-center{
    display: flex;
    align-items: center;
}
.remove-user-button{
    background-color: red !important;
    border-radius: 30px !important;
    padding: 10px 30px !important;
    font-size: 10px !important;
    color: #ffffff !important;
    font-weight: 800 !important;
    float: right;
}
.users-section-title{
    font-size: 22px;
    font-weight: bold;
    color: #404040;
    margin-left: -1em;
}
.router-link{
    background-color: #51c0db;
    color: #ffffff;
    padding: 1em 10em;
    font-size: 12px;
    font-weight: bold;
    border: none;
    border-radius: 20px;
    text-decoration: none;
    margin-left: -2em;
}