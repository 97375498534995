
.horizontal-scroll-menu .react-horizontal-scrolling-menu--item {
    min-width: 16em;
    margin-right: 2em;
}
.horizontal-scroll-menu .video-slider-media{
    height: 7em;
    width: 100%;
    object-fit: cover;
    /* padding: 5px; */
}
.horizontal-scroll-menu .video-card{
    border-radius: 10px !important;
    height: 100%;
    width: 16rem;
    overflow: visible;
}
.horizontal-scroll-menu .video-card-content{
    padding: 10px !important;
}
.ellipsis {
    display: -webkit-box;
    max-width: 80%;
    margin: 0 auto;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 10px !important;
  }
.horizontal-scroll-menu .arrow-buttons{
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    right: 1%;
    user-select: none;
    margin-top: 5.5em;
    height: fit-content;
    width: fit-content;
    border-radius: 100%;
    padding: 2px;
    border: none;
    background-color: #ffffff;
}
.horizontal-scroll-menu .arrow-left{
    position: absolute;
    left: 5em;
    z-index: 1;
}
.horizontal-scroll-menu .arrow-right{
    position: absolute;
    right: 6em;
    z-index: 1;
}
.horizontal-scroll-menu .scroll-menu .video-title{
    font-size: 12px;
}
.horizontal-scroll-menu .scroll-menu .video-title{
    font-size: 12px;
}         
.horizontal-scroll-menu .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .horizontal-scroll-menu .react-horizontal-scrolling-menu--scroll-container {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  .horizontal-scroll-menu .scroll-menu-title{
      font-size: 14px;
      margin-left: 2.5em;
      margin-bottom: 5px;
  }
  .horizontal-scroll-menu .react-horizontal-scrolling-menu--wrapper {
      /* margin-bottom: 2em; */
  }
  .horizontal-scroll-menu .video-card:hover .overlay{
    /* display: block; */
    position: absolute;
    top: 0;
    height: 100%;
    width: 16em;
    border-radius: 10px;
    /* right: 0; */
    background-color: #404040;
    opacity: 0.4;
    /* z-index: 1; */
  }
  .horizontal-scroll-menu .video-card:hover .play-button{
    display: block !important;
    position: absolute;
    top: 0;
    left: 45%;
    z-index: 1;
    padding: 0;
    height: 30px;
    width: 30px;
    align-items: center;
  }
  .horizontal-scroll-menu .video-card:hover .play-button .MuiSvgIcon-root{
    margin-top: 2px;
}
  .horizontal-scroll-menu .video-scroll-menu-text{
      font-size: 12px;
      margin: 0 !important;
  }
  .horizontal-scroll-menu .video-card .favorite-button{
    background-color: transparent;
    outline: none;
    color: #51c0db;
    border: none;
    position: absolute;
    right: 0.5em;
    bottom: 0.5em;
    z-index: 3;
    cursor: pointer;
}
.horizontal-scroll-menu .video-card .favorite-icon{
    color: #51c0db;
}
.horizontal-scroll-menu .video-scroll-menu-text{
    font-size: 12px;
    margin: 0 !important;
}
.horizontal-scroll-menu .font-weight-bold{
    font-weight: bold;
}