.admin-nav-avatar {
    cursor: pointer;
    position: fixed;
    top: 20px;
    right: 1em;
    width: 30px;
    height: 30px;
    color: #404040;
}
.admin-nav-options{
    font-weight: bold;
    color: #404040;
    position: fixed;
    right: 5em;
    font-size: 14px;
}

.logout-button{
    background-color: transparent;
    font-size: 14px;
    font-weight: bold;
    width: fit-content;
    outline: none;
    border: none;
    cursor: pointer;
}