.upload-form-input input{
    background-color: #ffffff !important;
    border-radius: 30px !important;
    text-decoration: none;
    padding: 5px 10px;
    width: 23em;
}
.add-category-form{
    margin-top: 3em;
}
.add-category-form .MuiInput-underline:after, .add-category-form .MuiInput-underline:before, .add-category-form .MuiInput-underline:hover:not(.Mui-disabled):before{
    border-bottom: none;
}
.add-category-form label{
    min-width: 9em;
    width: 9em;
    font-size: 12px;
    font-weight: bold !important;
    color: #000000;
}
.upload-form-label{
    min-width: 9em;
    width: 9em;
    font-size: 12px;
    font-weight: bold !important;
    color: #000000;
}
.add-category-form .align-items-center{
    display: flex;
    align-items: center;
    margin-bottom: 1em;
    justify-content: center;
}
.upload-button{
    background-color: #51c0db;
    color: #ffffff;
    padding: 8px 20px;
    font-size: 10px;
    border: none;
    border-radius: 20px;
    margin-left: 27rem;
    cursor: pointer;
    margin-top: 1em;
}
.subcat-empty-margin
{
    margin-left: 2em;
}
.subcat-margin
{
    margin-left: 6.5em;
}
.subcat-margin-add
{
    margin-left: 8em;
}
@media screen and (max-width:899px) {
    .add-category-form{
        padding: 0 1em;
        margin:0;
    }
    
}
@media screen and (max-width:599px) {
    .upload-form-input input{
        min-width: 100%;
    }
    
    .align-items-center{
        justify-content: flex-start !important;
    }
    .upload-form-input .MuiOutlinedInput-root{
        margin-right: 1em;
    }
}

@media screen and (max-width:499px) {
    .add-category-form .align-items-center{
        justify-content: end !important;
    }
    .upload-button{
        margin: 1em 0;
    }
}