.dashboard-page .dark-calendar-card-content {
  padding-bottom: 16px !important;
  padding-right: 8px !important;
}
.dashboard-page .video-tile-block {
  padding: 0 2em 0 2em !important ;
  margin-bottom: 1em;
  height: 100%;
}
.dashboard-page .calendar-block {
  padding: 0 2em !important;
}
.dashboard-page .quick-search-card-content {
  padding: 5px 3px !important;
}
.dashboard-page .quick-search-card {
  margin-bottom: 12px;
  text-align: center;
  width: 5.3em;
  height: 5.3em;
  border-radius: 8px !important;
  font-size: 12px;
  border: 2px solid #404040;
}
.dashboard-page .quick-search-block {
  padding: 2em 4.9em 0 4.9em !important;
}
.dashboard-page .quick-search-titles {
  font-size: 10px;
  font-weight: 600;
}
.dashboard-page .media {
  height: 25em;
  width: 100%;
  object-fit: cover;
  padding: 10px;
}
.dashboard-page .video-duration {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}
.dashboard-page .video-title {
  margin: 0 0 5px 0;
}
.dashboard-page .signup-btn {
  background-color: #51c0db !important;
  color: #ffffff !important;
  border-radius: 15% !important;
  font-size: 10px !important;
  border-radius: 30px !important;
}
.dashboard-page .carousel-btn {
  /* background-color: #51c0db !important; */
  background-color: #fe365f;
  color: #ffffff !important;
  font-size: 18px !important;
  border-radius: 7px !important;
}
.dashboard-page .carousel-btn:hover {
  background-color: #cb2b4c;
}
.dashboard-page .carousel-img {
    opacity: 1;
    height: 50em;
}
.dashboard-page .carousel-nxt-pre-btn {
  background-color: transparent;
}
.dashboard-page .carousel-nxt-pre-btn:hover {
  background-color: transparent;
  opacity: 1 !important;
}
.dashboard-page .dark-calendar-card {
  background-color: #404040 !important;
  border-radius: 10px !important;
}
.dashboard-page .calendar-block p {
  margin: 0 !important;
  color: #ffffff;
}
.dashboard-page .starting-time p {
  font-size: 12px;
  font-weight: 500;
}
.dashboard-page .starting-time {
  text-align: right;
  padding-bottom: 0 !important;
  margin-right: 0.5em !important;
  z-index: 3;
}
.dashboard-page .event-detail {
  font-size: 14px;
  padding-top: 0 !important;
  font-weight: 600;
  z-index: 3;
}
.dashboard-page .font-weight-bold {
  font-weight: bold;
}
.dashboard-page .expand-more-btn {
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  margin-top: 1em;
  z-index: 4;
}
.dashboard-page .react-horizontal-scrolling-menu--item {
  min-width: 16em;
  margin-right: 2em;
}
.dashboard-page .video-slider-media {
  height: 7em;
  width: 100%;
  object-fit: cover;
  /* padding: 5px; */
}
.dashboard-page .video-card {
  border-radius: 10px !important;
}
.dashboard-page .video-card-content {
  padding: 10px !important;
}
.dashboard-page .arrow-buttons {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  right: 1%;
  user-select: none;
  margin-top: 5.5em;
  height: fit-content;
  width: fit-content;
  border-radius: 100%;
  padding: 2px;
  border: none;
  background-color: #ffffff;
}
.dashboard-page .arrow-left {
  position: absolute;
  left: 15em;
  z-index: 1;
}
.dashboard-page .arrow-right {
  position: absolute;
  right: 2em;
  z-index: 1;
}
.dashboard-page .scroll-menu .video-title {
  font-size: 12px;
}
.dashboard-page .scroll-menu .video-title {
  font-size: 12px;
}
.dashboard-page
  .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.dashboard-page .react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.dashboard-page .custom_root_class .react-add-to-calendar__dropdown {
  bottom: 0.5em;
  top: auto;
  z-index: 4;
  font-size: 14px;
  width: 84%;
}

.dashboard-page .scroll-menu-title {
  font-size: 14px;
  margin-left: 2.5em;
  margin-bottom: 5px;
}
.dashboard-page .react-horizontal-scrolling-menu--wrapper {
  /* margin-bottom: 2em; */
}
.dashboard-page .video-card:hover .overlay {
  /* display: block; */
  position: absolute;
  top: 0;
  height: 100%;
  width: 16em;
  border-radius: 10px;
  /* right: 0; */
  background-color: #404040;
  opacity: 0.4;
  /* z-index: 1; */
}
.dashboard-page .video-card:hover .play-button {
  display: block !important;
  position: absolute;
  top: 0;
  left: 45%;
  z-index: 1;
  padding: 0;
  height: 30px;
  width: 30px;
  align-items: center;
}
.dashboard-page .video-card:hover .play-button .MuiSvgIcon-root {
  margin-top: 2px;
}
.dashboard-page .video-scroll-menu-text {
  font-size: 12px;
  margin: 0 !important;
}
.dashboard-page .expand-btn {
  background-color: transparent !important;
  color: #ffffff !important;
  border-radius: 15% !important;
  font-size: 10px !important;
  border-radius: 30px !important;
}
.dashboard-page .expand-area {
  justify-content: center;
  background: #404040;
  margin-top: 5px;
  padding: 0;
}
.dashboard-page .quick-search-icons {
  height: 100%;
}
.dashboard-page .event-container {
  /* margin: 1em 0; */
  padding: 0 0 1em 0;
}
.dashboard-page .image-bg {
  width: 100%;
  position: absolute;
  opacity: 0.5;
  height: 100%;
  border-radius: 10px;
}
.dashboard-page .video-card .favorite-button {
  background-color: transparent;
  outline: none;
  color: #51c0db;
  border: none;
  position: absolute;
  right: 0.5em;
  bottom: 0.5em;
  z-index: 3;
  cursor: pointer;
}
.dashboard-page .video-card .favorite-icon {
  color: #51c0db;
}
.dashboard-page .react-horizontal-scrolling-menu--wrapper {
  padding: 1em 2em;
}

.dashboard-page
  .dark-calendar-card-content
  .react-horizontal-scrolling-menu--scroll-container {
  display: block;
  height: 25em;
}
.dashboard-page
  .dark-calendar-card-content
  .react-horizontal-scrolling-menu--wrapper {
  padding: 0;
}
.dashboard-page
  .dark-calendar-card-content
  .react-horizontal-scrolling-menu--item {
  margin-right: 2px;
}
.dashboard-page .dark-calendar-card-content .video-card-content {
  padding: 10px 0px 10px 10px !important;
}
.dashboard-page .dark-calendar-card-content .video-card {
  background-color: transparent;
  box-shadow: none;
}
.dashboard-page .dark-calendar-card-content .event-container {
  margin-bottom: 0;
}
.dashboard-page .dark-calendar-card-content .arrow-right {
  bottom: -8.2em;
  right: 46%;
  z-index: 5;
  background: #404040;
  color: #ffffff;
}
.dashboard-page .dark-calendar-card-content .arrow-left {
  left: unset !important;
  top: -5.3em;
  right: 46%;
  z-index: 5;
  background: #404040;
  color: #ffffff;
}
.dashboard-page .dark-calendar-card-content {
  padding: 2em 7px !important;
}
.dashboard-page .link {
  text-decoration: none;
}
.dashboard-page .carrousel-headings {
  background: #51c0db;
  color: #000000;
  border-radius: 10px;
  font-size: 12px;
  padding: 7px 12px;
  width: fit-content;
}

/* .dashboard-page .video-tile-block .css-ohwg9z .css-1f8sh1y {
  min-height: 460px;
} */

.dashboard-page .carousel-title {
  position: absolute;
  top: 10%;
  width: 100%;
  text-align: left;
  padding-left: 20px;
  color: white;
}

.dashboard-page .carousel-description {
  position: absolute;
  top: 30%;
  width: 70%;
  text-align: left;
  padding-left: 20px;
  font-size: 16px;
  color: white;
}

.dashboard-page .carousel-subdescription {
  position: absolute;
  top: 40%;
  width: 70%;
  text-align: left;
  padding-left: 20px;
  font-size: 16px;
  color: white;
}

@media screen and (max-width: 899px) {
  .dashboard-page .quick-search-block > div:nth-child(1) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .dashboard-page .arrow-left {
    left: 3em;
  }
  .dashboard-page .scroll-menu-title {
    margin-left: 2.5em;
  }
  .dashboard-page .quick-search-block {
    padding: 2em 2em 0 4em !important;
  }
}
@media screen and (max-width: 599px) {
  .dashboard-page .quick-search-block {
    padding: 2em 1em 0 3em !important;
  }
  .dashboard-page .quick-search-block .quick-search-card {
    margin-right: 1em;
    height: 3.5em;
    width: 3.5em !important;
  }
  .dashboard-page .quick-search-icons {
    width: 2em !important;
  }
  .dashboard-page .carousel-title {
    font-size: 28px;
  }
  .dashboard-page .carousel-description {
    top: 20%;
    width: 90%;
    font-size: 14px;
  }
  .dashboard-page .carousel-subdescription {
    top: 33%;
    width: 90%;
    font-size: 14px;
  }
  /* .dashboard-page .carousel-img {
        opacity: 0.4;
        height: 25em;
    } */
}

.dashboard-page .carousel-img {
  aspect-ratio: 16 / 9;
  width: calc(100vw - 4rem);
  height: auto;
}


@media screen and (min-width: 768px) {
  .dashboard-page .carousel-img {
    aspect-ratio: 16 / 6;
  }
}