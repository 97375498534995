.footer {
    color: #404040;
    background-color: #404040;
    /* background-image:url('../../img/homepage-banner.jpg') ; */
    /* background-repeat: no-repeat; */
    /* background-size: 100%; */
    /* background-position:center; */
    padding: 25px;
    max-width: 100%;
    position: relative;
    border-top: 2px solid #ffffff;
}
/* .footLogo {
    
} */

.footLogoImg {
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 10px;
    width: 200px;
    cursor: pointer;
    z-index: 1;
}

.footInfo {
    /* border-top: #404040;
    border-style: solid none none none; */
    /* text-align: center; */
    position: relative;
    z-index: 2;

}

.footAdress{
    color: #ffffff;
}

.footHead {
    color: #ffffff;
    padding-top: 20px;
    padding-bottom: 5px;
}

/* .footLink {
    font-weight: 600;

} */

.footInfo a:link{
    color: #ffffff;
    text-decoration: none;
}

.footInfo a:visited{
    color: #ffffff;
}

.footInfo a:hover{
    color: #ffffff;
    text-decoration: underline;
}

.footHead a:active{
    color: #51c0db;
}

.footHead a:link{
    color: #ffffff;
    text-decoration: none;
}

.footHead a:visited{
    color: #ffffff;
}

.footHead a:hover{
    color: #51c0db;
}

.footHead a:active{
    color: #51c0db;
}


.faIconFoot {
    font-size: 30px !important;
    margin: 5px;
}

.footStore {
    width: 150px;
    height: auto;
}

.footLogo{
    position: relative;
    z-index: 2;
    border-bottom: 2px solid #ffffff;
}

.footLogo img{
    width: 8em;
    padding-bottom: 1.5em;
}

.footer .overlay{
    /* display: block; */
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    right: 0;
    left: 0;
    background-color: #404040;
    opacity: 0.8;
}


@media screen and (max-width: 599px) {
    .footInfo{
        text-align: center;
    }
}
@media screen and (min-width: 550px) {

    .footLogoImg {
        display: block;
        margin-left: 0px;
        margin-right: 0px;
        padding-bottom: 10px;
        width: 200px;
        cursor: pointer;
        z-index: 1;
    }

    .footInfo {
        display: grid;
        grid-template-columns: auto auto;
    
    }

}

@media screen and (min-width: 768px) {

    .footInfo {
        display: grid;
        grid-template-columns: auto auto auto;
    
    }

}

@media screen and (min-width: 960px) {
    .footer{
        padding: 4em 8em;
    }
    .footer .MuiGrid-item:nth-child(2), .footer .MuiGrid-item:nth-child(3){
        text-align: center;
    }
    .footer .MuiGrid-item:nth-child(4){
        text-align: end;
    }
    .footContact, .footLink{
        text-align: left;
    }
}

