.live-event-tags-input{
    background-color: #ffffff;
    width: 24.5em;
    height:2.2em;
    border-radius: 30px !important;
}
.upload-form-input input{
    background-color: #ffffff !important;
    border-radius: 30px !important;
    text-decoration: none;
    padding: 5px 10px;
    width: 23em;
}
.upload-form{
    margin-top: 5em;
}
.upload-form .MuiInput-underline:after, .upload-form .MuiInput-underline:before, .upload-form .MuiInput-underline:hover:not(.Mui-disabled):before{
    border-bottom: none;
}
.upload-form label{
    min-width: 8em;
    width: 8em;
    font-size: 12px;
    font-weight: bold !important;
    color: #000000;
}
.upload-form .align-items-center{
    display: flex;
    align-items: center;
    margin-bottom: 1em;
    justify-content: center;
}
.select-input{
    background-color: #ffffff;
    width: 24.5em;
    border-radius: 30px !important;
}
.select-input .MuiSelect-select{
    padding: 5px 10px !important;
}
.notes-input{
    background-color: #ffffff;
    width: 24.5em;
    border-radius: 30px;
}
.notes-input textarea{
    padding: 0.5em 1em;
}
.upload-button{
    background-color: #51c0db;
    color: #ffffff;
    padding: 8px 20px;
    font-size: 10px;
    border: none;
    border-radius: 20px;
    margin-left: 25rem;
    cursor: pointer;
    margin-top: 1em;
}
.MuiOutlinedInput-notchedOutline{
    border: none !important;
}
.MuiSelect-icon{
    color: #51c0db !important;
    font-size: 1.8rem !important;
}
.upload-form-date-picker{
    width: 24.5em;
    background: #ffffff;
    border-radius: 30px;
}
.upload-form-date-picker input{
    padding: 5px 10px;
}
.upload-form-date-picker button{
    color: #51c0db;
}
.upload-form-date-picker .MuiCalendarPicker-root .Mui-selected{
    background-color: #51c0db !important;
}
.required:after {
    content:" *";
    color: red;
}
.add-video-icon{
    fill: #51c0db !important;
    background-color: #ffffff;
    /* padding: 3px; */
    margin-left: 5px;
    /* height: 1.5em !important; */
    /* width: 1.5em !important; */
}
@media screen and (max-width:899px) {
    .upload-form{
        padding: 0 1em;
        margin:0;
    }
    
}
@media screen and (max-width:599px) {
    .upload-form-input input{
        width: 22em;
        min-width: 100%;
    }
    
    .align-items-center{
        justify-content: flex-start !important;
    }
    .upload-form-input .MuiOutlinedInput-root{
        margin-right: 1em;
    }
}

@media screen and (max-width:499px) {
    .upload-form .align-items-center{
        justify-content: end !important;
    }
    .upload-button{
        margin: 1em 0;
    }
}