.session-player-page .container{
    display: grid;
    gap: 15px;
    align-items: flex-start;
    padding: 0px 1% 5px;
    width: 100%;
    margin-top: 0;
}
.session-player-page .container .video-list{
    background: #fff;
    border-radius: 5px;
    height: 30.5em;
    overflow-y: scroll;
    
}
.session-player-page .container .video-list .video img{
    width: 150px;
    height: 90px;
    border-radius: 5px;
}
.session-player-page .container .video-list::-webkit-scrollbar{
    width: 7px;
}
.session-player-page .container .video-list::-webkit-scrollbar-track{
    background: #ccc;
    border-radius: 50px;
}
.session-player-page .container .video-list::-webkit-scrollbar-thumb{
    background: #666;
    border-radius: 50px;
}
.session-player-page .container .video-list .video{
    display: flex;
    align-items: left;
    gap: 15px;
    background: #f7f7f7;
    border-radius: 5px;
    margin: 10px;
    padding: 10px;
    border: 1px solid rgba(0,0,0,0.1);
    cursor: pointer;
    height: auto;
}
.session-player-page .container .video-list .video-title .title, .session-title{
    color: #333;
    text-align: left;
    margin-bottom: 5px;
    margin-top: 10px;
}
.session-player-page .container .video-list .video-title .subtitle, .session-description{
    font-size: 15px;
    color: rgb(87, 87, 87);
    font-weight: 700;
    text-align: left;
    margin-bottom: 5px;
}
.session-player-page .container .video-list .video-title .duration{
    font-size: 12px;
    color: grey;
    font-weight: normal;
    text-align: left;
}
.session-player-page .container .video-list .video:hover{
    background: #eee;
}
.session-player-page .container .video-list .video.active{
    background: darkgray;
}
.session-player-page .container .video-list .video-title{
    display: flex;
    flex-direction: column;
    align-items: left;
}
.session-player-page .search-bar{
    display: flex;
    flex-direction: column;
}
.session-player-page .tags-badge-line{
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    font-size: 10px!important;
    /* margin: 0 auto; */
    max-width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}
@media (max-width:991px) {
    .container{
        padding: 10px;
    }
}
@media (max-width:768px) {
    .container{
        padding: 10px;
    }
}