@import "./font-awesome/font-awesome";

.react-add-to-calendar {
    .react-add-to-calendar__dropdown{
        border: 1px solid #51c0db;
        border-radius: 2px;
    }

    -webkit-font-smoothing: antialiased;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .004);
    position: relative;
    display: inline-block;
    margin: 0 ;

    &__wrapper {
        zoom: 1;
        cursor: pointer;
    }

    &__button {
        padding: 10px;
        background-color: #51c0db !important;
        border: 1px solid #51c0db;
        border-radius: 3px;
        color: #ffffff;

        &--light {
            background-color: #fff;
        }
    }

    &__icon {
        &--right {
            padding-left: 5px;
        }

        &--left {
            padding-right: 5px;
        }
    }

    &__dropdown {
        position: absolute;
        top: 30px;
        left: 1px;
        width: 93%;
        padding: 5px 0 5px 8px;
        box-shadow: 1px 3px 6px rgba(0, 0, 0, .15);
        border: 1px solid #a8a8a8;
        background-color: #fff;
        text-align: left;

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            li {

                a {
                    color: #51c0db;
                    text-decoration: none;

                    i {
                        padding-right: 10px;
                    }
                }
            }
        }
    }
}

.custom_root_class{
    position: inherit;
    .react-add-to-calendar__dropdown{
        bottom: -5.5em;
        top: auto;
        z-index: 4;
        font-size: 14px;
        width: 95%;
    }
}

.react-all-videos-add-to-calendar {
    .react-add-to-calendar__dropdown{
        border: 1px solid #51c0db;
        border-radius: 2px;
        width: 200px;
        z-index: 4;
    }
    padding: 0 3px 0 5px;
    background-color: transparent !important;
    border: none;
    color: #51c0db;
    -webkit-font-smoothing: antialiased;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .004);
    position: absolute;
    right: 0.8em;
    bottom: 2.5em;
    display: inline-block;
    margin: 0 ;
    overflow: visible;

    &__wrapper {
        zoom: 1;
        cursor: pointer;
    }

    &__button {
        font-size: 23px;
        font-weight: bold;
        border-radius: 3px;

    }

    &__dropdown {
        position: absolute;
        top: 13.5em;
        left: 1px;
        width: 93%;
        padding: 5px 0 5px 8px;
        box-shadow: 1px 3px 6px rgba(0, 0, 0, .15);
        border: 1px solid #a8a8a8;
        background-color: #fff;
        text-align: left;

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            li {

                a {
                    color: #51c0db;
                    text-decoration: none;

                    i {
                        padding-right: 10px;
                    }
                }
            }
        }
    }
}