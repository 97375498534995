.tags-form-input input{
    background-color: #ffffff !important;
    border-radius: 30px !important;
    text-decoration: none;
    padding: 5px 10px;
    width: 23em;
}
.tags-form{
    margin-top: 3em;
}

.head-title{
    text-align: center;
    color: #51c0db;
    font-weight: bold;
    padding-bottom: 1em;
}

.tags-form .MuiInput-underline:after, .tags-form .MuiInput-underline:before, .tags-form .MuiInput-underline:hover:not(.Mui-disabled):before{
    border-bottom: none;
}
.tags-form label{
    min-width: 7em;
    width: 7em;
    font-size: 12px;
    font-weight: bold !important;
    color: #000000;
}
.tags-form-label{
    min-width: 7em;
    width: 7em;
    font-size: 12px;
    font-weight: bold !important;
    color: #000000;
}
.tags-form .align-items-center{
    display: flex;
    align-items: center;
    margin-bottom: 1em;
    justify-content: center;
}
.upload-button{
    background-color: #51c0db;
    color: #ffffff;
    padding: 8px 20px;
    font-size: 10px;
    border: none;
    border-radius: 20px;
    margin-left: 26rem;
    cursor: pointer;
    margin-top: 1em;
}
@media screen and (max-width:899px) {
    .tags-form{
        padding: 0 1em;
        margin:0;
    }
}
@media screen and (max-width:599px) {
    .tags-form-input input{
        min-width: 100%;
    }

    .align-items-center{
        justify-content: flex-start !important;
    }

    .tags-form-input .MuiOutlinedInput-root{
        margin-right: 1em;
    }
}

@media screen and (max-width:499px) {
    .tags-form .align-items-center{
        justify-content: end !important;
    }
    .upload-button{
        margin: 1em 0;
    }
}