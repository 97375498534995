.loginForm {
    height: 100vh;
}

.loginFormContent {
    position: absolute;
    top: 17%;
    max-width: 300px;
    padding: 70px;
    margin-right: auto;
    margin-left: auto;
    color: #fff;
    background: #404040;
    border-radius: 10px;
    left: 0;
    right: 0;
} 
.loginFormContent > form {
    display: grid;
    flex-direction: column;
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    grid-auto-rows: auto;
}

.loginFormContent > form > h1 {
    text-align: center;
    /* margin-top: 0; */
    margin-bottom: 25px;
}

.loginFormContent > form > input {
    outline-width: 0;
    height: 40px;
    margin-bottom: 14px;
    border-radius: 5px;
    border: none;
    padding: 5px 15px;
}

.loginFormContent > form > button {
    padding: 16px 20px;
    font-size: 1rem;
    color: #f1f1f1;
    border-radius: 5px;
    background-color: #51c0db;
    font-weight: 600;
    border: none;
    cursor: pointer;
    margin-top: 20px;
}

.loginFormOpret {
    text-align: left;
    margin-top: 25px;
    /* margin-bottom: 0; */
}

.loginFormOpret a:link{
    color: #f1f1f1;
}

.loginFormOpret a:visited{
    color: #f1f1f1;
}

.loginFormOpret a:hover{
    color: #51c0db;
}

.loginFormOpret a:active{
    color: #51c0db;
}

.loginFormContent .options-container
{
    display: flex;
    justify-content: space-between;
}

@media screen and (max-width: 500px) {
    .loginFormContent {
        position: absolute;
        top: 20%;
        max-width: 100%;
        padding: 30px;
        margin-right: 2em;
        margin-left: 2em;
        word-wrap: break-word;
    }

    .loginFormContent > form > h1 {
        text-align: center;
        margin-bottom: 25px;
        width: 100%;
        font-size: 18px;
    }
    
    .loginFormContent > form > p {
        margin: 0px;
        width: 100%;
        font-size: 14px;
    }
}

#forgot-password-link {
    color:#51c0db;
    text-decoration: none;
}

#forgot-password-link:hover {
    text-decoration: underline;
}
