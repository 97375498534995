.section-title{
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    margin-left: -13em;
    color: #404040;
}
.create-user-form .create-user-button{
    background-color: #51c0db;
    color: #ffffff;
    padding: 8px 20px;
    font-size: 10px;
    border: none;
    border-radius: 20px;
    margin-left: 23rem;
}
.create-user-form .create-user-form-input input{
    background-color: #ffffff !important;
    border-radius: 30px !important;
    text-decoration: none;
    padding: 5px 10px;
    width: 23em;
}
.create-user-form{
    margin-top: 2em;
}
.create-user-form .MuiInput-underline:after, .create-user-form .MuiInput-underline:before, .create-user-form .MuiInput-underline:hover:not(.Mui-disabled):before{
    border-bottom: none;
}
.create-user-form label{
    width: 6em;
    min-width: 6em;
    font-size: 12px;
    font-weight: bold !important;
    color: #000000;
}
.create-user-form .align-items-center{
    display: flex;
    align-items: center;
    margin-bottom: 1em;
    justify-content: center;
}

@media screen and (max-width:899px) {
    .create-user-form{
        padding: 0 1em;
        margin:0;
    }
    
}
@media screen and (max-width:599px) {
     .section-title{
        margin-left: 1em;
        text-align: inherit;
    }
    .create-user-form-input input{
        width: 22em !important;
        min-width: 100%;
    }
    
    .align-items-center{
        justify-content: flex-start !important;
    }
    .create-user-form-input .MuiOutlinedInput-root{
        margin-right: 1em;
    }
}

@media screen and (max-width:499px) {
    .create-user-form .align-items-center:last-child{
        justify-content: end !important;
    }
    .create-user-button{
        margin: 1em 0 !important;
    }
}