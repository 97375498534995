.quick-search-page{
    padding: 0 1em 1em 1em;
}
.quick-search-page .page-title{
    display: flex;
    align-items: center;
    text-transform: capitalize;
}
.quick-search-page .page-title img{
    /* height: 30px; */
    width: 30px;
    margin-right: 10px;
}