.spinner-container
{
    position: fixed;
    top:0;
    display:flex; 
    z-index:10;
    background:rgba(40, 40, 40, .8);
    align-items:center; 
    justify-content:center; 
    height:100%;
    width:100%;
}