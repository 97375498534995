body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f1f1f1 !important;
  font-family: 'Montserrat' !important;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.progress-bar{
  color: #51c0db !important;
}
.tag-badge{
  background: #51c0db;
  color: #ffffff;
  border-radius: 10px;
  font-size: 10px;
  padding: 2px 5px;
  margin-right: 5px;
  border: 1px solid;
  margin-top: 5px;
}
#fade-wrapper {
  display: none;
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.5);
}
html{
  scroll-behavior:smooth;
}