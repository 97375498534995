.player-grid{
    background-color: #ffffff;
    padding: 0.5em !important;
    border-radius: 5px !important;
    height: fit-content;
}
.Player{
    margin-top: 1em;
}
.player-category{
    margin-top: 0;
    font-size: 12px;
    font-weight: 800 !important;
    color: #404040;
}
.chat-grid{
    text-align: center !important;
    margin-top: 1.9em !important;
}